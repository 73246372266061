import './App.css';
import React, { useState, useEffect, useRef } from "react";

import logoKecil from "./assets/Asset 5.png"
import eventSign from "./assets/Asset 6.png"
import founderSign from "./assets/Asset 7.png"
import map from "./assets/Asset 8.png"
import logoBesar from "./assets/Asset 9.png"
import field from "./assets/Asset 42.png"
import mazer from "./assets/Asset 11.png"
import cupboard from "./assets/Asset 12.png"
import sofa from "./assets/Asset 13.png"
import catCream from "./assets/Asset 14.png"
import preorder from "./assets/Asset 15.png"
import eggParadiseSign from "./assets/Asset 22.png"
import eggparadiseSandwich from "./assets/Asset 33.png"
import eggParadisePrice from "./assets/Asset 46.png"
import bbqthunderSign from "./assets/Asset 23.png"
import bbqthunderSandwich from "./assets/Asset 31.png"
import bbqthunderPrice from "./assets/Asset 47.png"
import naturebountySign from "./assets/Asset 25.png"
import naturebountySandwich from "./assets/Asset 30.png"
import naturebountyPrice from "./assets/Asset 45.png"
import fishmilkmayoSign from "./assets/Asset 24.png"
import fishmilkmayoSandwich from "./assets/Asset 32.png"
import fishmilkmayoPrice from "./assets/Asset 47.png"
import phone from "./assets/Asset 43.png"
import muteIcon from "./assets/Asset 36.png"
import unmuteIcon from "./assets/Asset 37.png"
import lampOffIcon from "./assets/Asset 39.png"
import lampOnIcon from "./assets/Asset 40.png"
import cat from "./assets/Asset 20.png"

import audioFile from "./assets/ninjaheroes.mp4";
import clickSound from "./assets/paperSoundEffect.wav"; // Path ke file audio

function App() {
  

  const handleClick = (name) => {
    alert(`${name} was clicked!`);
  };

  // State untuk mengontrol kondisi speaker
  const [isMuted, setIsMuted] = useState(false);
  const audioRef = useRef(null); // Gunakan useRef untuk menyimpan instance audio
  useEffect(() => {
    // Inisialisasi instance audio sekali saja
    audioRef.current = new Audio(audioFile);
    audioRef.current.loop = true; // Setel audio untuk loop
    return () => {
      // Bersihkan instance audio saat komponen di-unmount
      audioRef.current.pause();
      audioRef.current = null;
    };
  }, []);
  const toggleMute = () => {
    if (!audioRef.current) return; // Pastikan instance audio ada
    if (isMuted) {
      audioRef.current.pause(); // Hentikan audio
    } else {
      audioRef.current.play(); // Putar audio
    }
    setIsMuted(!isMuted); // Toggle state
  };

  const [isLampOn, setIsLampOn] = useState(false); // State untuk lampu
  const toggleLamp = () => {
    setIsLampOn(!isLampOn); // Ubah status lampu (on/off)
  };

  // State untuk gambar yang dipilih
  const [selectedImage, setSelectedImage] = useState(null);
  // Fungsi untuk menangani klik gambar
  const handleImageClick = (imageName) => {
    console.log(imageName); // Memastikan gambar yang dipilih
    setSelectedImage(imageName); // Menyimpan gambar yang dipilih

    setIsSofaClicked(imageName === 'leancanvas'); // Jika gambar sofa diklik, ubah state

    const audio = new Audio(clickSound); // Gunakan file yang diimport
    audio.play(); // Memutar audio
  };
  // Fungsi untuk menutup overlay
  const closeOverlay = () => {
    setSelectedImage(null); // Menghapus gambar yang dipilih

    setIsSofaClicked(false); // Reset state
  };

  const [isSofaClicked, setIsSofaClicked] = useState(false);
  const imageRef = useRef(null);
  // Tambahkan timeout kecil agar scroll terjadi setelah render selesai
  setTimeout(() => {
    if (imageRef.current) {
      imageRef.current.parentElement.scrollLeft =
        imageRef.current.offsetWidth * 0.25; // Scroll ke 25% dari lebar gambar
    }
  }, 300); // Tunggu animasi render selesai

  const handlePreorderClick = () => {
    const phoneNumber = '6289619586930'; // Nomor WhatsApp (gunakan format internasional tanpa 0 di depan, ganti 0 dengan 62 untuk Indonesia)
    const message = encodeURIComponent(
      "Halo! Saya tertarik untuk melakukan preorder sandwich lezat Anda. Bisa tolong berikan detailnya? Terima kasih!"
    );
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${message}`;

    // Arahkan ke URL WhatsApp
    window.open(whatsappURL, '_blank');
  };
  
  return (
    <div className="container">
      <div className="background" >
        <img src={sofa} className= {`sofa ${isLampOn ? "glow-effect" : ""}`} onClick={() => handleImageClick('leancanvas')}/>
        <img src={logoKecil} className= {`logoKecil ${isLampOn ? "glow-effect" : ""}`} onClick={() => handleImageClick('comingsoon')}/>
        <img src={eventSign} className= {`eventSign ${isLampOn ? "glow-effect" : ""}`} onClick={() => handleImageClick('PosterEvent')} />
        <img src={founderSign} className= {`founderSign ${isLampOn ? "glow-effect" : ""}`} onClick={() => handleImageClick('PosterFounder')}/>
        <img src={logoBesar} className= {`logoBesar ${isLampOn ? "glow-effect" : ""}`} onClick={() => handleImageClick('comingsoon')}/>
        <img src={field} className= {`field ${isLampOn ? "glow-effect" : ""}`} onClick={() => handleImageClick('support')}/>
        <img src={cupboard} className= {`cupboard ${isLampOn ? "glow-effect" : ""}`} onClick={() => handleImageClick('comingsoon')}/>
        <img src={mazer} className= {`mazer ${isLampOn ? "glow-effect" : ""}`} onClick={() => handleImageClick('testimoni')}/>
        <img src={catCream} className= {`catCream ${isLampOn ? "glow-effect" : ""}`} onClick={() => handleImageClick('testimoni')}/>
        <img src={preorder} className= {`preorder ${isLampOn ? "glow-effect" : ""}`} onClick={handlePreorderClick}/>
        <a
          href="https://maps.app.goo.gl/PJedHd427tdXYEY66"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={map} className= {`map ${isLampOn ? "glow-effect" : ""}`}/>
        </a>
        <a
          href="https://www.instagram.com/gowichhh?igsh=OGx3ZWduNDM0NGRj"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={phone} className={`phone ${isLampOn ? "glow-effect" : ""}`}/>
        </a>
        <img src={bbqthunderSign} className= {`bbqthunderSign ${isLampOn ? "glow-effect" : ""}`} onClick={() => handleImageClick('PosterBbq')}/>
        <img src={eggParadiseSign} className= {`eggparadiseSign ${isLampOn ? "glow-effect" : ""}`} onClick={() => handleImageClick('PosterEgg')}/>
        <img src={naturebountySign} className= {`naturebountySign ${isLampOn ? "glow-effect" : ""}`} onClick={() => handleImageClick('PosterNature')}/>
        <img src={fishmilkmayoSign} className= {`fishmilkmayoSign ${isLampOn ? "glow-effect" : ""}`} onClick={() => handleImageClick('PosterFishmilk')} />
        <img src={bbqthunderSandwich} className= {`bbqthunderSandwich ${isLampOn ? "glow-effect" : ""}`} onClick={() => handleImageClick('PosterBbq')}/>
        <img src={eggparadiseSandwich} className= {`eggparadiseSandwich ${isLampOn ? "glow-effect" : ""}`} onClick={() => handleImageClick('PosterEgg')}/>
        <img src={naturebountySandwich} className= {`naturebountySandwich ${isLampOn ? "glow-effect" : ""}`} onClick={() => handleImageClick('PosterNature')}/>
        <img src={fishmilkmayoSandwich} className= {`fishmilkmayoSandwich ${isLampOn ? "glow-effect" : ""}`} onClick={() => handleImageClick('PosterFishmilk')}/>
        <img src={bbqthunderPrice} className= {`bbqthunderPrice ${isLampOn ? "glow-effect" : ""}`}onClick={() => handleImageClick('PosterBbq')}/>
        <img src={eggParadisePrice} className= {`eggparadisePrice ${isLampOn ? "glow-effect" : ""}`} onClick={() => handleImageClick('PosterEgg')}/>
        <img src={naturebountyPrice} className= {`naturebountyPrice ${isLampOn ? "glow-effect" : ""}`} onClick={() => handleImageClick('PosterNature')}/>
        <img src={fishmilkmayoPrice} className={`fishmilkmayoPrice ${isLampOn ? "glow-effect" : ""}`} onClick={() => handleImageClick('PosterFishmilk')}/>
        <img src={cat} className={`cat ${isLampOn ? "glow-effect" : ""}`} onClick={() => handleImageClick('testimoni')}/>
        {/* Elemen Speaker */}
        <img
          src={isMuted ? muteIcon : unmuteIcon}
          alt="Speaker"
          className="speaker"
          onClick={toggleMute} // Tambahkan event onClick
        />
        {/* Ikon Lampu */}
        <img
          src={isLampOn ? lampOnIcon : lampOffIcon}
          alt="Lamp"
          className="lamp-icon"
          onClick={toggleLamp}
        />
      </div>
      {/* Overlay dengan gambar yang dipilih */}
      {selectedImage && (
        <div className="overlay" onClick={closeOverlay}>
          <div className='container-overlay' onClick={closeOverlay}>
            <div class="transparent-element" onClick={closeOverlay}></div>
            <img ref={imageRef} src={require(`./assets/${selectedImage}.png`)} alt="overlay" className={`overlay-image ${isSofaClicked ? "sofa-clicked" : ""}`} onClick={(e) => e.stopPropagation()}/>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
